.ql-editor .ql-video {
  display: block;
  max-width: 100%;
  width: 100%;
  height: 50%;
}
.ql-editor .ql-text{
  color: aqua;
}

.ql-editor{
  font-family: "Arial";
}


/* Set content font-families */

.ql-font-courier {
  font-family: "courier";
}

.ql-font-arial {
  font-family: "Arial";
}
